const main = async() => {
    const { default: Sticky } = await import(/* webpackChunkName: "sticky" */ "sticky-js");
    const instance = new Sticky('.sticky-wrapper');
};

// Execute async function
main().then((value) => {
});

if (module.hot) {
    module.hot.accept();
}